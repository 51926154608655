import React from 'react'
import WithIsScrolled from "./WithIsScrolled"

const Header = (props) => (

    <WithIsScrolled>
        {({ isScrolled }) => (
            <header className={ (isScrolled ? 'header' : 'header  alt') }>
                <nav>
                    <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
                </nav>
            </header>
        )}
    </WithIsScrolled>

)

export default Header
